<template>
    <div>
        <el-col :span="24" class="topTools">
            <el-form :inline="true">
                <el-form-item label="关键字:">
                    <el-input
                        placeholder="输入关键字筛选"
                        clearable
                        @clear='clearContent'
                        v-model="filterText"
                        @input="(e) => (filterText = utils.validForbid(e))"
                        >
                    </el-input>
                </el-form-item>
                <!-- <el-form-item label="菜单类型:">
                    <el-select clearable v-model="type" @clear='clearContent'>
                        <el-option
                            v-for="item in typeValue"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item>
                    <tool-bar class="editSom" :buttonList="buttonList" @callFunction="callFunction"></tool-bar>
                </el-form-item>
            </el-form>
        </el-col>

        <!--列表-->
        <el-table
            class="bodyTools"
            :data="treeData"
            highlight-current-row
            v-loading="listLoading"
            @selection-change="selsChange"
            @current-change="selectCurrentRow"
            row-key="id"
            border
            lazy
            max-height="680"
            :load="load"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            style="width: 100%;"
            ref="multipleTable"
        >
            <!-- <el-table-column type="selection" width="50" align="center"></el-table-column> -->
            <!-- <el-table-column type="index" label="序号" width="80" align="center"></el-table-column> -->
            <el-table-column label="菜单/按钮" width>
                <template slot-scope="scope">
                    <i class="fa" :class="scope.row.icon"></i>
                    {{scope.row.name}}
                </template>
            </el-table-column>
            <el-table-column prop="routingPath" label="路由地址" width></el-table-column>
            <el-table-column prop="linkUrl" label="API接口" width></el-table-column>
            <el-table-column prop="isButton" label="是否按钮" width="130;" align="center">
                <template slot-scope="scope">
                <el-tag
                    :type="!scope.row.isButton  ? 'danger' : 'success'"
                    disable-transitions
                >{{!scope.row.isButton ? "否":"是"}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="funcEvent" label="按钮事件" width="180"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="200" align="center"></el-table-column>
            <el-table-column prop="isHide" label="是否隐藏" width="130;" align="center">
                <template slot-scope="scope">
                <el-tag
                    :type="!scope.row.isHide  ? 'danger' : 'success'"
                    disable-transitions
                >{{!scope.row.isHide ? "否":"是"}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="isEnable" label="启用标识" width="130;" align="center">
                <template slot-scope="scope">
                <el-tag
                    :type="!scope.row.isEnable  ? 'danger' : 'success'"
                    disable-transitions
                >{{!scope.row.isEnable ? "禁用":"启用"}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="type" label="菜单类型"  :formatter="typeFormatter"></el-table-column>
        </el-table>
        <!--新增界面-->
        <el-dialog
            :title="'新增'"
            :visible.sync="addFormVisible"
            v-model="addFormVisible"
            :close-on-click-modal="false"
        >
            <el-form :model="addForm" label-width="100px" :rules="addFormRules" ref="addForm" style="max-width: 700px;">
                <el-form-item label="菜单名称：" prop="Name">
                    <el-input v-model="addForm.Name" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>

                <el-form-item label="路由地址：" prop="Code">
                    <el-tooltip placement="top">
                        <div slot="content">
                            如果是一级目录，请填‘-’字符，子集目录请输入/xx/xx即可
                            <br />如果是按钮，请输入空格即可
                            <br />如果是外链，请带上协议，比如 https://www.------.com
                            <br />
                        </div>
                        <el-input v-model="addForm.Code" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="描述：" prop="Description">
                    <el-input v-model="addForm.Description" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="启用标识：" prop="Enabled">
                    <el-select lect v-model="addForm.Enabled" placeholder="请选择状态">
                        <el-option
                        v-for="item in statusList"
                        :key="item.value"
                        :label="item.Name"
                        :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="菜单类型:">
                    <el-select v-model="addForm.type" placeholder="请选择菜单类型">
                        <el-option
                            v-for="item in typeValue"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="排序：" prop="OrderSort">
                    <el-input v-model="addForm.OrderSort" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item prop="IsButton" label="是否按钮：" width sortable>
                    <el-switch v-model="addForm.IsButton"></el-switch>
                </el-form-item>
                <el-form-item label="按钮事件：" prop="Func">
                    <el-input v-model="addForm.Func" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="Icon：">
                    <el-input v-model="addForm.Icon" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item prop="IsHide" label="隐藏菜单：" width sortable>
                    <el-switch v-model="addForm.IsHide"></el-switch>
                </el-form-item>
                <el-form-item prop="PidArr" label="父级菜单：" width sortable>
                    <el-cascader
                        style="width: 400px"
                        v-model="addForm.PidArr"
                        :options="options"
                        :props="defaultProps"
                        :key="isResouceShow"
                        filterable
                        change-on-select
                    ></el-cascader>
                </el-form-item>
                <el-form-item prop="PidArr" label="API接口：" width sortable>
                    <el-cascader
                        style="width: 400px"
                        v-model="addForm.apiMid"
                        :props="defaultProps1"
                        filterable
                        change-on-select
                        :placeholder="cascTips"
                    >
                    </el-cascader>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisible = false">取消</el-button>
                <el-button type="primary" @click.native="addSubmit('addForm')" :loading="addLoading">提交</el-button>
            </div>
        </el-dialog>
        <!--新增界面-->
        <el-dialog
            :title="'编辑'"
            :visible.sync="addFormVisiblec"
            v-model="addFormVisiblec"
            :close-on-click-modal="false"
        >
            <el-form :model="addForm" label-width="100px" :rules="addFormRules" ref="addForm" style="max-width: 700px;">
                <el-form-item label="菜单名称：" prop="Name">
                    <el-input v-model="addForm.Name" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>

                <el-form-item label="路由地址：" prop="Code">
                    <el-tooltip placement="top">
                        <div slot="content">
                            如果是一级目录，请填‘-’字符，子集目录请输入/xx/xx即可
                            <br />如果是按钮，请输入空格即可
                            <br />如果是外链，请带上协议，比如 https://www.------.com
                            <br />
                        </div>
                        <el-input v-model="addForm.Code" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="描述：" prop="Description">
                    <el-input v-model="addForm.Description" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="启用标识：" prop="Enabled">
                    <el-select lect v-model="addForm.Enabled" placeholder="请选择状态">
                        <el-option
                        v-for="item in statusList"
                        :key="item.value"
                        :label="item.Name"
                        :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="菜单类型:">
                    <el-select v-model="addForm.type" placeholder="请选择菜单类型">
                        <el-option
                            v-for="item in typeValue"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="排序：" prop="OrderSort">
                    <el-input v-model="addForm.OrderSort" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item prop="IsButton" label="是否按钮：" width sortable>
                    <el-switch v-model="addForm.IsButton"></el-switch>
                </el-form-item>
                <el-form-item label="按钮事件：" prop="Func">
                    <el-input v-model="addForm.Func" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="Icon：">
                    <el-input v-model="addForm.Icon" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item prop="IsHide" label="隐藏菜单：" width sortable>
                    <el-switch v-model="addForm.IsHide"></el-switch>
                </el-form-item>
                <el-form-item prop="PidArr" label="父级菜单：" width sortable>
                    <el-cascader
                        style="width: 400px"
                        v-model="addForm.PidArr"
                        :options="options"
                        :props="defaultProps"
                        :key="isResouceShow"
                        filterable
                        change-on-select
                    ></el-cascader>
                </el-form-item>
                <el-form-item prop="PidArr" label="API接口：" width sortable>
                    <el-cascader
                        style="width: 400px"
                        v-model="addForm.apiMid"
                        :props="defaultProps1"
                        filterable
                        change-on-select
                        :placeholder="cascTips"
                    >
                    </el-cascader>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisiblec = false">取消</el-button>
                <el-button type="primary" @click.native="addSubmit('addForm')" :loading="addLoading">提交</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import toolBar from "@/components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import { getMenuPermissionsTree,getApiModulesInfoTree,addMenuPermissionsInfo,updateMenuPermissionsInfo,deleteMenuPermissionsInfo,getMenuTree } from "@/api/api";
import util from "../../../util/date";
import Qs from 'qs'
let a = 1;
export default {
    components: { toolBar },
    data(){
        return{
            utils: '',
            buttonList: [],
            treeData: [],   //列表树
            listLoading: false,
            maps: new Map(),
            filterText: '', //查询关键字
            type: '', //查询类型
            isAdd: true,
            options: [],
            defaultProps: {
                children: "children",
                label: "name",
                value:'id',
            },
            defaultProps1: {
                children: "children",
                label: "name",
                value:'id',
                lazy: true,
                lazyLoad (node, resolve) {
                    if(node.data){
                        var nodeId = node.data.id
                    }else{
                        var nodeId = 1
                    }
                    var params = {}
                    if(node.data){
                        if(node.data.id == "00"){
                            params.parentId = 0
                        }else{
                            params.parentId = node.data.id
                        }
                    }else{
                        params = ''
                    }
                    getApiModulesInfoTree(params)
                    .then(res => {
                        var data = res.data
                        if(data.success){
                            const cities = data.response.map(
                                (value, i) => ({
                                    id: value.id,
                                    name: value.name +' '+ value.linkUrl,
                                    // leaf: node.level >= 2,
                                    disabled: !value.isApi
                                })
                            );
                            if(a == 1){
                                var obj = {
                                    id: "00",
                                    name: "无需api"
                                }
                                a++
                                cities.push(obj)
                            }
                            if(nodeId == "00"){
                                var citiesc = []
                                resolve(citiesc);
                                return
                            }
                            resolve(cities);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
                }
            },
            modules: [], //接口api列表
            addLoading: false,
            addFormVisible: false, //新增界面是否显示
            addFormVisiblec: false, //编辑界面是否显示
            addFormRules: {
                Name: [{ required: true, message: "请输入菜单名称", trigger: "blur" }],
                Code: [{ required: true, message: "请输入路由地址", trigger: "blur" }],
                // Func: [{ required: true, message: "请输入按钮事件", trigger: "blur" }],
            },
            statusList: [
                { Name: "启用", value: true },
                { Name: "禁用", value: false }
            ],
            //新增/编辑界面数据
            isResouceShow: 1,
            cascTips: "请选择",
            selectVal: '',
            addForm: {
                CreateBy: "",
                CreateId: "",
                dataId: "",
                PidArr: [], //父级菜单
                type: 163,
                apiMid: [],
                OrderSort: 1,
                Name: "",
                Code: "",
                Description: "",
                Icon: "",
                Func: "",
                Enabled: true,
                IsButton: false,
                IsHide: false,
            },
            typeValue: [
                {
                    value: 163,
                    name: "客户端"
                },
                {
                    value: 164,
                    name: "web菜单"
                },
                {
                    value: 165,
                    name: "通用"
                },
            ]
        }
    },
    methods: {
        // 筛选框清空，重新获取数据
        clearContent(){
            this.getNavTree()
        },
        // 点击了查询，新增，编辑，删除
        callFunction(item) {
            this[item.func].apply(this, item);
        },
        // 刷新
        doRefresh() {
            this.getNavTree();
        },
        load(tree, treeNode, resolve) {
            this.maps.set(tree.id, {tree, treeNode, resolve});
            let params = {
                parentId: tree.id,
                isEnable: tree.isEnable
            };
            getMenuPermissionsTree(params).then(res => {
                resolve(res.data.response);
            });
        },
        // 获取菜单树
        getNavTree(params) {
            this.listLoading = true
            getMenuPermissionsTree(params).then(res => {
                var data = res.data
                if(data.success){
                    this.listLoading = false
                    var result = data.response
                    this.treeData = result;
                    this.$refs.multipleTable.clearSelection();
                    this.maps.forEach((item, index) => {
                        this.$set(this.$refs.multipleTable.store.states.treeData[index], 'loaded', false);
                        this.$set(this.$refs.multipleTable.store.states.treeData[index], 'expanded', false);
                    })
                }
            })
        },
        // 获取父级菜单
        getParNavTree(propsVal) {
            var params = {
                userID: JSON.parse(localStorage.getItem("user")).id
            }
            getMenuTree(params).then(res => {
                var data = res.data
                if(data.success){
                    // this.options = res.Response.children
                    var optionArr = []
                    optionArr.push(data.response)
                    this.options = optionArr
                    this.addForm.PidArr = propsVal
                }
            })
        },
        selectCurrentRow(val) {
            this.selectVal = val
        },
        selsChange: function(val) {
            if (val.length > 1) {
                this.$refs.multipleTable.clearSelection(); //清空列表的选中
                this.$refs.multipleTable.toggleRowSelection(val[val.length-1]); //只显示选中最后一个 这时val还是多选的列表(就是你选中的几个数据)
            } else if (val.length === 1) {
                this.multipleTable = val[val.length-1];
                this.selectVal = val[0]
            } else {
                this.multipleTable = []; //this.multipleTable是选中行的最后一条数据
                this.selectVal = '';
            }
        },
        // 查询
        getMenuPermissionsTree(){
            var params = {
                menuPermissionsName : this.filterText
            }
            this.getNavTree(params);
        },
        // 新增
        handleAdd() {
            this.isAdd = true
            this.addFormVisible = true
            a = 1
            this.cascTips = '请选择'
            this.addForm.CreateBy = ''
            this.addForm.CreateId = ''
            this.addForm.dataId = ''
            // this.addForm.PidArr = []
            // this.addForm.apiMid = []
            this.addForm.OrderSort = 1
            this.addForm.Name = ''
            this.addForm.type = 163
            this.addForm.Code = ''
            this.addForm.Description = ''
            this.addForm.Icon = ''
            this.addForm.Func = ''
            this.addForm.Enabled = true
            this.addForm.IsButton = false
            this.addForm.IsHide = false
            // this.addForm.IskeepAlive = false
            this.getParNavTree();
        },
        // 编辑
        handleEdit() {
            if(this.selectVal == ''){
                this.$message({
                    message: '请选择需要编辑的菜单一项',
                    type: 'warning'
                });
                return false;
            }
            var val = this.selectVal
            this.isAdd = false
            this.addFormVisiblec = true

            this.addForm.CreateBy = ''
            this.addForm.CreateId = ''
            this.addForm.dataId = val.id
            // this.addForm.PidArr = val.ParentIdList
            if(val.apiModulesInfoId == 0){
                this.addForm.apiMid = ["00"]
            }else{
                this.addForm.apiMid = []
                this.cascTips = val.apiModulesInfoName
            }
            this.addForm.OrderSort = val.sortNo //排序
            this.addForm.Name = val.name //菜单名称
            this.addForm.type = val.type //菜单类型
            this.addForm.Code = val.routingPath //路由地址
            this.addForm.Description = val.description //描述
            this.addForm.Icon = val.icon
            if(val.funcEvent){
                this.addForm.Func = val.funcEvent  //按钮事件
            }else{
                this.addForm.Func = '-'
            }
            this.addForm.type = val.type    //状态启用/禁用
            this.addForm.Enabled = val.isEnable    //状态启用/禁用
            this.addForm.IsButton = val.isButton  //是否按钮
            this.addForm.IsHide = val.isHide  //是否隐藏

            var propsVal = val.parentIdList
            this.getParNavTree(propsVal);
        },
        handleDel() {
            if(this.selectVal == ''){
                this.$message({
                    message: '请选择需要删除的菜单一项',
                    type: 'warning'
                });
                return false;
            }
            var val = this.selectVal
            var params = {
                id: val.id
            }
            this.$confirm('确认删除该数据吗?', '提示', {
                type: 'warning'
            }).then(() => {
                deleteMenuPermissionsInfo(params).then(res => {
                    var data = res.data
                    if(data.success){
                        this.$message({
                            message: "删除成功！",
                            type: "success"
                        });
                        this.doRefresh();
                    }else{
                        this.$message.error(data.message);
                    }
                })
                .catch(err => {
                    this.$message({
                        message: "删除失败！",
                        type: "error"
                    });
                })
            })
            .catch(() => {
            })
        },
        // 回车按钮
        enterKey(item) {
            this.addSubmit(item);
        },
        // 新增/编辑提交按钮
        addSubmit(formName) {
            var _this = this
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var val = this.addForm
                    if(this.isAdd){
                        var params = {
                            routingPath: val.Code,
                            name: val.Name,
                            isButton: val.IsButton,
                            isHide: val.IsHide,
                            type: val.type,
                            funcEvent: val.Func,
                            sortNo: val.OrderSort,
                            icon: val.Icon,
                            description: val.Description,
                            isEnable: val.Enabled,
                        }
                        if(val.PidArr){
                            if(val.PidArr.length > 0){
                                // params.parentId = val.PidArr.pop()
                                params.parentId = val.PidArr[val.PidArr.length-1]
                            }
                        }
                        if(val.apiMid){
                            if(val.apiMid.length > 0){
                                // if(val.apiMid.pop() == "00"){
                                if(val.apiMid[val.apiMid.length-1] == "00"){
                                    params.apiModulesInfoId = 0
                                }else{
                                    // params.apiModulesInfoId = val.apiMid.pop()
                                    params.apiModulesInfoId = val.apiMid[val.apiMid.length-1]
                                }
                            }
                        }
                        if(JSON.parse(localStorage.getItem("user")).id > 0){
                            params.createUserId = JSON.parse(localStorage.getItem("user")).id
                        }else{
                            this.$message({
                                message: "用户信息为空，先登录",
                                type: "error"
                            });
                            _this.$router.replace(
                                "/"
                            );
                            return
                        }
                        this.addLoading = true
                        addMenuPermissionsInfo(params).then(res => {
                            var data = res.data
                            if(data.success){
                                this.$message({
                                    message: "新增成功！",
                                    type: "success"
                                });
                                this.addFormVisible = false;
                                this.addLoading = false;
                                this.doRefresh();
                            }else{
                                this.$message.error(data.message);
                                this.addLoading = false;
                            }
                        })
                        .catch(err => {
                            this.$message({
                                message: "新增失败！",
                                type: "error"
                            });
                        })
                    }else{
                        var params = {
                            routingPath: val.Code,
                            name: val.Name,
                            isButton: val.IsButton,
                            isHide: val.IsHide,
                            type: val.type,
                            funcEvent: val.Func,
                            sortNo: val.OrderSort,
                            icon: val.Icon,
                            description: val.Description,
                            isEnable: val.Enabled,
                            id: val.dataId,
                        }
                        if(val.PidArr.length > 0){
                            // params.parentId = val.PidArr.pop()
                            params.parentId = val.PidArr[val.PidArr.length-1]
                        }
                        if(val.apiMid.length > 0){
                            // if(val.apiMid.pop() == "00"){
                            if(val.apiMid[val.apiMid.length-1] == "00"){
                                params.apiModulesInfoId = 0
                            }else{
                                // params.apiModulesInfoId = val.apiMid.pop()
                                params.apiModulesInfoId = val.apiMid[val.apiMid.length-1]
                            }
                        }
                        if(JSON.parse(localStorage.getItem("user")).id > 0){
                            params.createUserId = JSON.parse(localStorage.getItem("user")).id
                        }else{
                            this.$message({
                                message: "用户信息为空，先登录",
                                type: "error"
                            });
                            _this.$router.replace(
                                "/"
                            );
                            return
                        }
                        this.addLoading = true
                        updateMenuPermissionsInfo(params).then(res => {
                            var data = res.data
                            if(data.success){
                                this.$message({
                                    message: "更新成功！",
                                    type: "success"
                                });
                                this.addFormVisiblec = false;
                                this.addLoading = false;
                                this.doRefresh();
                            }else{
                                this.$message.error(data.message);
                                this.addLoading = false;
                            }
                        })
                        .catch(err => {
                            this.$message({
                                message: "编辑失败！",
                                type: "error"
                            });
                        })
                    }
                }
            })
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.createTime || row.createTime == ""
            ? ""
            : util.formatDate.format(new Date(row.createTime), "yyyy-MM-dd hh:mm:ss");
        },
        typeFormatter: function (row, column) {
            // return this.typeValue.find((type) => {return type.value === row.type}).name;
            return !row.type || row.type == ""
                ? ""
                : this.typeValue.find((type) => {return type.value === row.type}).name;
        },
    },
    watch: {
        options() {
            this.isResouceShow++
        }
    },
    created() {
        this.utils = util
        this.doRefresh();
    },
    mounted() {
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
.topTools{
    position fixed;
    top 110px;
    z-index 50;
    background-color #fff;
}
.bodyTools{
    margin-top 65px;
}
</style>